

const convertToEther = (web3, price) => {
	if (web3) {
		return web3.utils.fromWei(price, 'ether');
	}
};

export const convertToDays = (number) => {
	return (number / 86400)
}

export default convertToEther;
