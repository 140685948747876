// import Web3 from "web3";
// import numeral from "numeral";
// import BigNumber from "bignumber.js";

// const _format =
//   (precision = 5) =>
//   (num) => {
//     if (Object.is(NaN, +num)) {
//       return parseFloat("0").toFixed(precision);
//     }
//     const zeros = Array(precision).fill(0).join("");
//     return numeral(num).format(`0,0.${zeros}`);
//   };

//   export const Format = {
//     Currency: (num) => {
//         return _format(2)(num);
//       },

//       toDecimalPlaces(
//         num,
//         decimalPlaces,
//         roundingMode = "ROUND_DOWN"
//       ) {
//         const b = Format.convertToBigN(num);
//         return b.decimalPlaces(decimalPlaces, BigNumber[roundingMode]).toString();
//       },
//       convertToBigN: (data) => new BigNumber(data),
//       convertToBN: (data) => Web3.utils.toBN(data),

//       TokenValue: (
//         input,
//         _precision = 6,
//         enforcePecision = false
//       ) => {
//         input = String(input).replace(/,/g, "");
    
//         if (!isValidNumber(input)) return "0.00";
    
//         const isExpontentialNotation = input.includes("e-");
    
//         let bnValue;
    
//         if (isExpontentialNotation) {
//           bnValue = Format.fromENotation(input);
//         } else {
//           const safeValue = Format.toWei(Format.safeEtherValue(input), "ether");
    
//           const valueAsBigNumber = Format.convertToBigN(
//             // @ts-ignore
//             Format.convertToBN(safeValue)
//           );
//           bnValue = valueAsBigNumber.div(Math.pow(10, 18));
//         }
//         const expectedValue = bnValue.toFormat(_precision);
    
//         const precision = (num) => Array(num).fill(0).join("");
    
//         const bValue = enforcePecision
//           ? numeral(expectedValue).format(`0,0.${precision(_precision)}`)
//           : numeral(expectedValue).format(`0,0.00[${precision(_precision - 2)}]`);
//         return Object.is(+bValue, NaN) ? expectedValue : bValue;
//       },
//       fromENotation(value) {
//         return Format.convertToBigN(value);
//       },
//       toWei: (value, decimalOrUnit) => {
//         if (value === "") return 0;
//         if (typeof decimalOrUnit === "string")
//           return Web3.utils.toWei(value, decimalOrUnit);
    
//         const newValue = new BigNumber(value)
//           .times(new BigNumber(10).pow(decimalOrUnit))
//           .toFixed();
//         return newValue;
//       },
    
//   }

  export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });