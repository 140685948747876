

const convertToWei = (web3, price) => {
	if (web3) {
		console.log("web333", web3)
		return web3.utils.toWei(price, 'ether');
	}else{
		console.log("web3 is not available")
	}
};

export default convertToWei;
