import React from "react";
import "./Header.css";

function Header({connectWallet, balance, stableCoinBalance, isConnected}) {
  // const hit = () => {
  //   console.log(connectWallet)
  // }
  return (
    <div className="header">
      <div className="container">
        <div>
          <p className="logo">
            Jollof.<span className="rfc">RCF</span>
          </p>
        </div>
        <div>
          {isConnected? 
          <div>
        <p>{`${balance} CELO`}</p>
        <p>{`${stableCoinBalance} USDC`}</p> </div> :
          <button onClick={connectWallet}>Connect Wallet</button>}
        </div>
      </div>
    </div>
  );
}

export default Header;
