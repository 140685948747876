import React from 'react'
import Header from '../components/Header/Header'
import Pool from '../components/Pool/Pool'
import {
  AlertProvider,
  AlertOutlet,
  useAlert,
} from 'react-bootstrap-hooks-alert'
import 'react-bootstrap-hooks-alert/dist/Alert.css'
import './Home.css'

function Home({
  connectWallet,
  configData,
  deposit,
  web3,
  balance,
  stableCoinBalance,
  isConnected,
  availableAmount,
  withdraw
}) {
  return (
    <AlertProvider timeouts={{ warning: 2000, success: 1000, info: 2000 }}>
      <AlertOutlet className="alert" />
      <div>
        <Header
          connectWallet={connectWallet}
          balance={balance}
          stableCoinBalance={stableCoinBalance}
          isConnected={isConnected}
        />
        <Pool configData={configData} deposit={deposit} web3={web3} availableAmount={availableAmount} withdraw={withdraw} />
      </div>
    </AlertProvider>
  )
}

export default Home
